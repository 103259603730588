export const reviewValidations = {
  order: 2,
  name: "Review validations",
  keywords: "split unified diff duplicate dedupe deduplication citation",
  subcategory: "Validate database changes",
  markdown: `# Review validations

  On the {% inlineAppIcon iconName="shieldCheck" %}**Available**{% /inlineAppIcon %} section of the {% inlineRouterLink articleId="sidebar" %}Validations tab{% /inlineRouterLink %}, you will see a group of changes to review. Your job is to decide whether each proposed change is accurate.

  ![Validation screenshot](https://assets.towerhunt.com/site/reviewValidationsOverview0.png)

  Let's take a closer look at the different parts of the validation tool:

  ## Group summary

  A summary of the current group of proposed changes is always visible at the top of the tab. You can see the total number of changes in the group (including how many are additions vs removals), how many changes you've voted on, the reputation level of the contributor, and when the group was submitted. If you've viewed every change in the group, you are also able to apply a single vote type to the entire group.

  ![Change group overview screenshot](https://assets.towerhunt.com/site/reviewValidationsGroupSummary0.png)

  ## Batches of related changes

  Below the group summary, changes are organized into batches based on the type of real estate information they are related to. At the top of each batch is a description of the type of data that the changes are related to (ex. a property, a company, an investment).

  ![Batches of changes screenshot](https://assets.towerhunt.com/site/reviewValidationsChangeBatch0.png)

  {% callout type="tip" %}
  **Tip:** Take advantage of the {% inlineAppIcon iconName="rectangleStack" /%}{% inlineRouterLink articleId="the-role-of-the-property-diagram" %}**Property diagram view**{% /inlineRouterLink %} when you see it. Many types of data that relate to a property make the most sense when you see them in the diagram as they will appear to other users.
  {% /callout %}

  When there are multiple batches, only the first one is expanded by default. Once you begin voting, successive changes expand to help you focus.

  {% inlineVideo videoSource="https://assets.towerhunt.com/site/reviewValidationsExpand0.mp4" marginStyles="my-4" /%}

  Each change sits in a visual container that can be {% inlineAppIcon iconName="expandCollapse" /%}**expanded and collapsed**. The container's title bar displays the type of datapoint (ex. name, physical location) and whether it is an addition or a removal.

  Inside the container are sections intended to help you review the data:

  ## Differences

  The differences section shows a text representation of the affected data. For many types of data, this is an easy way to see what has changed.

  ![Diff screenshot](https://assets.towerhunt.com/site/reviewValidationsDifferences0.png)

  ## Deduplication support

  ### Properties

  Proposed property locations are displayed on a map along with nearby existing properties to help protect against duplicates. Make sure to look carefully and also consider {% inlineRouterLink articleId="organize-properties" %}how to organize properties that belong together{% /inlineRouterLink %}.

  {% inlineVideo videoSource="https://assets.towerhunt.com/site/reviewValidationsPropertyDedupe0.mp4" marginStyles="my-4" /%}

  ### Investments

  Investment-related data offers a chronological list of existing investments to help protect against duplicates. This is important because multiple users may track the same investment as a collection of {% inlineRouterLink articledId="what-is-safezone?" %}Safezone{% /inlineRouterLink %} datapoints. When users begin declassifying that investment, it is possible for multiple versions of the investment to be in the validation pipeline at the same time.

  ![Investment deduplication screenshot](https://assets.towerhunt.com/site/reviewValidationsDuplicateInvestments0.png)

  {% callout type="check" %}
  Validations are presented chronologically in an effort to reward first-movers in cases where potential duplicates exist.
  {% /callout %}

  ## Visual support

  Certain data types can be displayed on a map (ex. parcel boundaries) or shown as an image/file/link preview.

  ![Visual support screenshot](https://assets.towerhunt.com/site/reviewValidationsPolygon0.png)

  ## Citations

  {% inlineRouterLink articleId="add-a-citation" %}Citations{% /inlineRouterLink %} provide evidence to support the accuracy and credibility of datapoints.

  ![Diff citation screenshot](https://assets.towerhunt.com/site/reviewValidationsCitations0.png)

  ## Guidance

  Each change provides suggestions about what to consider before you vote. This can be helpful when there are many related changes and you'd like additional context about each one.

  ![Validation guidance screenshot](https://assets.towerhunt.com/site/reviewValidationsGuidance0.png)

  ### Split vs Unified view

  There are two options for viewing changes. In either view, additions are green and removals are red.

  ### Split view
  "Before" is on the left, "after" is on the right.
  ![Split view screenshot](https://assets.towerhunt.com/site/reviewValidationsSplitView0.png)

  ### Unified view
  "Before" and "after" are combined.
  ![Unified view screenshot](https://assets.towerhunt.com/site/reviewValidationsUnifiedView0.png)

  ### Changing views
  Toggle between split and unified view using the control at the bottom-right of the group summary.
  {% inlineVideo videoSource="https://assets.towerhunt.com/site/reviewValidationsSwitchView0.mp4" marginStyles="my-4" /%}

  ## Next steps

  Reviewing validations can be hard work, which is why validators can earn {% inlineRouterLink articleId="understand-reputation" %}reputation{% /inlineRouterLink %} and money! But to earn, you have to {% inlineRouterLink articleId="vote-on-validations" %}vote{% /inlineRouterLink %}.`,
};
